<template>
    <div class="homepage-list">
        <list :fs="fs" addLink="/admin/homepages/new" apiList="homepages?sort=rank" apiRemove="homepages" editLink="/admin/homepages" title="Homepage List"></list>
    </div>
</template>
<script>
import List from "@/components/List.vue";
export default {
    components: {
        List,
    },
    computed: {
        fs() {
            return [
                //
                { heading: "Label", data: "label", type: "text" },
                { heading: "Quantity", data: "quantity", type: "text" },
                { heading: "Rank", data: "rank", type: "number" },
                { heading: "Category", data: "category_id", type: "category" },
                { heading: "Mobile View Only", data: "mobileViewOnly", type: "boolean" },
                { heading: "Hot Only", data: "hotOnly", type: "boolean" },
                { heading: "New Only", data: "newOnly", type: "boolean" },
                { heading: "Sale Only", data: "saleOnly", type: "boolean" },
            ];
        },
    },
};
</script>

