<template>
    <div class="homepage-edit">
        <edit :fs="fs" :id="id" :title="title" @changed="changed" @saved="saved" api="homepages"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
export default {
    props: {
        id: {
            type: String,
        },
    },
    components: {
        Edit,
    },
    computed: {
        title() {
            return this.id ? "Edit Homepage" : "New Homepage";
        },
        fs() {
            return [
                //
                { heading: "Label", data: "label", type: "text", validate: "required" },
                { heading: "Quantity", data: "quantity", type: "number", default: 10 },
                { heading: "Quantity (Mobile)", data: "quantityMobile", type: "number", default: 10 },
                { heading: "Rank", data: "rank", type: "number", default: 0 },
                { heading: "Category", data: "category_id", type: "category" },
                { heading: "Background Color (#)", data: "backgroundColor", type: "text" },
                { heading: "Background Image", data: "backgroundImage", type: "image" },

                { heading: "Mobile View Only", data: "mobileViewOnly", type: "boolean" },
                { heading: "Hot Only", data: "hotOnly", type: "boolean" },
                { heading: "New Only", data: "newOnly", type: "boolean" },
                { heading: "Sale Only", data: "saleOnly", type: "boolean" },
            ];
        },
    },
    methods: {
        changed() {},
        saved() {
            this.$router.push("/admin/homepages");
        },
    },
};
</script>
