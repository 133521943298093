<template>
    <div class="homepage-index">
        <md-tabs @change="tabChange" ref="tabs">
            <md-tab :md-active="tabIndex==0" md-label="Sections">
                <homepage-list v-if="tabIndex==0"></homepage-list>
            </md-tab>
            <md-tab :md-active="tabIndex==1" md-label="New Section">
                <homepage-edit :id="id" v-if="tabIndex==1"></homepage-edit>
            </md-tab>
            <md-tab :md-active="tabIndex==2" :md-disabled="page!='edit'" md-label="Edit Section">
                <homepage-edit :id="id" v-if="tabIndex==2"></homepage-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
import HomepageList from "@/components/Homepage/HomepageList";
import HomepageEdit from "@/components/Homepage/HomepageEdit";

export default {
    name: "homepage-index",
    props: {
        page: {
            type: String,
            default: "list"
        },
        id: {
            type: String,
            default: ""
        }
    },
    components: {
        HomepageList,
        HomepageEdit
    },
    data() {
        return {
            tabIndex: 0,
            ready: false
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                switch (this.page) {
                    case "list":
                        this.tabIndex = 0;
                        break;
                    case "new":
                        this.tabIndex = 1;
                        break;
                    case "edit":
                        this.tabIndex = 2;
                        break;
                }

                setTimeout(() => {
                    this.ready = true;
                }, 0);
            }
        }
    },
    methods: {
        tabChange(index) {
            if (!this.ready) {
                return;
            }

            if (index == 0) {
                this.$router.push("/admin/homepages");
            }
            if (index == 1) {
                this.$router.push(`/admin/homepages/new`);
            }
            if (index == 2) {
                this.$router.push(`/admin/homepages/${this.id}`);
            }
        }
    }
};
</script>

